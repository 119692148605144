import React from 'react';
import { NavLink as RouterLink } from "react-router-dom";

export default function Trainings({ data }) {
  return (
    <div
      name="trainings"
      className="bg-black w-full h-full flex p-5 relative min-h-screen items-center justify-center"
    >
      <p className="text-4xl absolute inline top-20 object-center m-5 gap-20 text-gray-300 hover:text-gray-500 lg:grid-cols-1">
        <RouterLink to="/faq"> {data.nav3} </RouterLink>
      </p>
      <div className="m-5 center grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {["kezdo", "közephalado", "halado", "open1"].map((level, index) => (
          <div
            key={level}
            className="flex items-center justify-center min-h-screen"
          >
            <div className="relative bg-black w-full group max-w-md min-w-0 mx-auto mt-6 mb-6 break-words  border border-gray-300 shadow-2xl rounded-xl">
             
              <div className="pb-6">
                <div className="mt-20 text-center">
                  <h3 className="mb-1 text-2xl font-bold leading-normal text-gray-200">
                    {data[level]}
                  </h3>
                </div>
                <div className="pt-6 mx-6 mt-6 text-center border-t border-gray-200">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full px-6">
                      <p className="mb-4 font-light leading-relaxed text-gray-300">
                        {data[level === "kezdo" ? "basic" : level === "közephalado" ? "startup" : level === "halado" ? "motivated" : "open"]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center py-4 ">
                <RouterLink
                  to={`/tanarok/${index + 1}`}
                  className="text-red-500 hover:underline"
                >
                  Tanárok →
                </RouterLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
