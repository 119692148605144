import React, { useState } from 'react';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setSubmitted(true);
      // Itt kezelheted az email cím elküldését
      console.log('Feliratkozás sikeres!', email);
    }
  };

  return (
    <div className="flex justify-center items-center  bg-black">
      <div className="w-full max-w-sm text-center text-white px-4 py-6">
        <h2 className="text-2xl text-white font-light mb-2">Feliratkozás hírlevélre!</h2>
        <h3 className="text-base font-light mb-4">Ne maradj le az eseményekről, értesülj elsőként!</h3>

        {!submitted ? (
          <form onSubmit={handleSubmit} className="flex justify-center items-center gap-4">
            <input
              type="email"
              placeholder="Add meg az email címed"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="text-lg p-4 rounded-lg bg-transparent border-2 border-white placeholder-gray-400 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
            />
            <button
              type="submit"
              className="text-lg bg-red-500 text-white p-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-1 focus:ring-red-500"
            >
              Feliratkozás
            </button>
          </form>
        ) : (
          <p className="text-lg text-green-500">Köszönjük, hogy feliratkoztál!</p>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
