import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Hot from '../assets/hot-girly.png';
import { NavLink as RouterLink, Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Language from '../components/LanguageBtn';

export default function NavBar({ data, i18n }) {
  const [nav, setNav] = useState(false);
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);

  const handleClick = () => setNav(!nav);

  useEffect(() => {
    if (nav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    const closeMenuOnClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target)
      ) {
        setNav(false);
      }
    };

    document.addEventListener('mousedown', closeMenuOnClickOutside);

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', closeMenuOnClickOutside);
    };
  }, [nav]);

  return (
    <div name="nav" className="bg-black flex justify-between items-center h-20 mx-auto px-3 text-white">
      <div className="flex">
        <Link to="header" className="m-2">
          <img src={Hot} alt="logo" style={{ width: '60px' }} />
        </Link>
        <div className="m-7"></div>
        <Language i18n={i18n} />
      </div>

      <nav>
        <ul className="hidden md:flex">
          {/* Desktop menu items */}
          <li className="p-4 m-2 text-center">
            <ScrollLink to="about" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav1}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink to="teachers" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav2}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink to="calendar" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav4}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink to="trainings" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav3}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <RouterLink to="/events">
              {data.nav5}
            </RouterLink>
          </li>
          {/* <li className="p-4 m-2 text-center">
            <RouterLink to="/ref">
              {data.nav6}
            </RouterLink>
          </li> */}
          <li className="p-4 m-2 text-center">
            <ScrollLink to="contact" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav7}
            </ScrollLink>
          </li>
        </ul>

        <div
          onClick={handleClick}
          ref={menuIconRef}
          className="md:hidden z-10 cursor-pointer"
        >
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul
          ref={menuRef}
          className={`md:hidden ${
            !nav
              ? 'hidden'
              : 'absolute top-0 left-0 w-full h-screen bg-black flex flex-col justify-center items-center z-50'
          }`}
        >
          <button
            onClick={() => setNav(false)}
            className="absolute top-5 right-5 text-white text-2xl"
          >
            ✖
          </button>
          {/* Mobile menu items */}
          <li className="p-4 m-2 text-center">
            <ScrollLink onClick={handleClick} to="about" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav1}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink onClick={handleClick} to="teachers" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav2}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink onClick={handleClick} to="calendar" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav4}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <ScrollLink onClick={handleClick} to="trainings" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav3}
            </ScrollLink>
          </li>
          <li className="p-4 m-2 text-center">
            <RouterLink onClick={handleClick} to="/events">
              {data.nav5}
            </RouterLink>
          </li>
          {/* <li className="p-4 m-2 text-center">
            <RouterLink onClick={handleClick} to="/ref">
              {data.nav6}
            </RouterLink>
          </li> */}
          <li className="p-4 m-2 text-center">
            <ScrollLink onClick={handleClick} to="contact" smooth={true} duration={500} spy={true} offset={-80}>
              {data.nav7}
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
