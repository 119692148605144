import React, { useState } from 'react';
import Picture from '../assets/open.jpg';
import axios from 'axios';

export default function AboutWithRegistration({ data }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    course: 'oratipus',
    notes: '',
    acceptPolicy: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.acceptPolicy) {
      setError('Kérjük, fogadja el az adatvédelmi nyilatkozatot!');
      return;
    }
    setError('');
    setSuccess('');

    const SHEETDB_API_URL = 'https://sheetdb.io/api/v1/kk0574igg8nde';

    try {
      await axios.post(SHEETDB_API_URL, {
        data: [{
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          address: formData.address,
          course: formData.course,
          notes: formData.notes,
        }],
      });
      setSuccess('Az adatok sikeresen elküldve!');
    } catch (err) {
      setError(`Hiba történt az adatok küldése közben: ${err.response?.data?.error || err.message}`);
    }
  };

  return (
    <section className="relative bg-black lg:bg-grey-800">
    <div>
      <img className="object-cover h-full w-full" src={Picture} alt="us" />
      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl font-bold text-center">
        Ne maradj le! Jelentkezz nyílt napunkra most!
      </p>
      <button
        onClick={() => setModalOpen(true)}
        className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-transparent text-white border border-white py-2 px-6 rounded-md hover:bg-white hover:text-black transition"
      >
        Regisztráció
      </button>
    </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-black p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h1 className="text-2xl font-bold mb-4 text-gray-200">Jelentkezési Lap</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Vezetéknév"
                  className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Keresztnév"
                  className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Lakcím (Irányítószám, város, utca, házszám)"
                className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <select
                name="course"
                value={formData.course}
                onChange={handleChange}
                className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="oratipus" disabled>Melyik órát választod?</option>
                <option value="KEZDŐ">Kezdő</option>
                <option value="KÖZÉPHALADÓ">Középhaladó</option>
                <option value="HALADÓ">Haladó</option>
                <option value="OPEN">Open (bármikor lehet csatlakozni)</option>
              </select>
              <textarea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Megjegyzés ( Írd be kinek az órájára és mikor szeretnél menni)"
                className="w-full p-3 border rounded-md bg-gray-100 text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="acceptPolicy"
                  checked={formData.acceptPolicy}
                  onChange={handleChange}
                  className="h-5 w-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                />
                <label className="text-gray-400">
                  Az űrlap kitöltésével elfogadom az{' '}
                  <a href="/privacy" className="text-blue-500 hover:underline">adatvédelmi nyilatkozatot</a>.
                </label>
              </div>
              {error && <p className="text-red-500 text-sm">{error}</p>}
              {success && <p className="text-green-500 text-sm">{success}</p>}
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setModalOpen(false)}
                  className="bg-gray-600 text-white p-3 rounded-md hover:bg-gray-800 transition"
                >
                  Bezárás
                </button>
                <button
                  type="submit"
                  className="bg-red-600 text-white p-3 rounded-md hover:bg-red-800 transition"
                >
                  Jelentkezés
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
}